import React from 'react';

// Utils
import { isSection } from 'Utils/content/item-util';
import SectionUtil from 'Webapp/utils/content/section-util';

import TopicStoryBoardItem from 'Webapp/shared/app/views/section/topic/topic-storyboard-item';

import {
  ACCESSORY_SECTIONS,
  getAccessorySections,
} from 'Webapp/shared/concepts/accessory-sections';

import connector from 'Webapp/utils/connector';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';

import withT from 'ComponentLibrary/hocs/withT';

type TopicRelatedStoryboardsProps = {
  section: Flipboard.Section;
  t: Flipboard.TFunction;
} & ConnectResponsiveProps;

const TopicRelatedStoryboards: React.FC<TopicRelatedStoryboardsProps> = ({
  isPhone,
  section,
  t,
}) => {
  const currentSectionRemoteId = SectionUtil.getTopicSectionId(section);
  const topicStoryboards =
    currentSectionRemoteId &&
    getAccessorySections(
      ACCESSORY_SECTIONS.TOPIC_STORYBOARDS,
      currentSectionRemoteId,
    );
  const relatedStoryboardSection =
    topicStoryboards?.length > 0 && topicStoryboards[0];

  if (isPhone || !relatedStoryboardSection) {
    return null;
  }

  const filteredSectionTypes = relatedStoryboardSection.items.filter(isSection);

  const firstThreeStoryRelated = filteredSectionTypes?.slice(0, 3);

  const storyboardItems = firstThreeStoryRelated?.map(
    (relatedStoryBoardItem) => (
      <TopicStoryBoardItem
        key={relatedStoryBoardItem.oid}
        relatedStoryBoardItem={relatedStoryBoardItem}
      />
    ),
  );

  if (storyboardItems && storyboardItems.length > 0) {
    return (
      <div className="topic-section__storyboard-items-container">
        <section className="centered-column">
          <h2 className="topic-section__related-storyboard-label ui-subheading--large-standard">
            {t('storyboard_related_topic_label', {
              topic: section.title,
            })}
          </h2>
          <ul className="topic-section__storyboard-items">{storyboardItems}</ul>
        </section>
      </div>
    );
  }
  return null;
};

export default connector<TopicRelatedStoryboardsProps>(connectResponsive)(
  withT(TopicRelatedStoryboards),
);
