import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FlapUtil from 'Utils/content/flap-util';
import { getSectionPath } from 'Utils/content/flipboard-urls';
import ModifierClickAnchor from 'Webapp/shared/app/components/modifier-click-anchor';

function TopicSectionTabs({ subSections, feedSection, updateFeedSection }) {
  return (
    <div className="topic-header__section-tabs">
      {subSections &&
        subSections.map((subTopic) => {
          const isSelected =
            FlapUtil.normalizeRemoteid(subTopic.remoteid) ===
            FlapUtil.normalizeRemoteid(feedSection.remoteid);
          const className = classNames({
            'topic-header__section-tab': true,
            'topic-header__section-tab--selected': isSelected,
            'topic-header__section-tab--unselected': !isSelected,
          });
          return isSelected ? (
            <div key={subTopic.title} className={className}>
              {subTopic.title}
            </div>
          ) : (
            <ModifierClickAnchor
              onClick={() => updateFeedSection(subTopic)}
              name={`section_tab_${subTopic.title}`}
              key={subTopic.title}
              className={className}
              href={getSectionPath(subTopic)}
            >
              {subTopic.title}
            </ModifierClickAnchor>
          );
        })}
    </div>
  );
}

TopicSectionTabs.propTypes = {
  subSections: PropTypes.array.isRequired,
  feedSection: PropTypes.object.isRequired,
  updateFeedSection: PropTypes.func.isRequired,
};
export default TopicSectionTabs;
