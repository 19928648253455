import React from 'react';
import styled from '@emotion/styled';
import { TEXT_COLORS, ICON_COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import {
  CONTENT_STORY_HEADING_TYPES,
  UI_SUBHEADING_TYPES,
} from 'Style/typography';

import { truncate } from 'Style/truncate';
import SectionUtil from 'Webapp/utils/content/section-util';

import MagazineThumbnail from 'Webapp/shared/app/components/magazine-thumbnail';
import FollowButton from 'Webapp/shared/app/components/follow-button';
import MagazineAttribution from 'Webapp/shared/app/components/attribution/magazine';
import SectionAuthorAttribution from 'Webapp/shared/app/components/attribution/section-author-attribution';
import Badge from 'Webapp/shared/app/components/badge';
import WebLink from 'Webapp/shared/app/components/web-link';
import {
  ACCESSORY_SECTIONS,
  getAccessorySections,
} from 'Webapp/shared/concepts/accessory-sections';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';
import connectAuthentication, {
  ConnectAuthenticationProps,
} from 'Webapp/shared/app/connectors/connectAuthentication';

const MAX_MAGAZINES = 3;

const RecommendedMagazines = styled.div({
  marginTop: SPACING.XLARGE,
  paddingRight: SPACING.MEDIUM,
  width: '365px',
  marginBottom: 'auto',
  maxHeight: '350px',
  display: 'grid',
  gap: SPACING.LARGE,
  justifySelf: 'end',
});

const Title = styled.span(UI_SUBHEADING_TYPES.LARGE_STANDARD);

const RecommendedMagazine = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  alignItems: 'center',
});

const MagazineAttributionContainer = styled.div({
  width: '198px',
  'a.magazine_attribution': {
    ...CONTENT_STORY_HEADING_TYPES.SMALL,
    ...truncate('190px', 2),
  },
});
const MagazineSecondaryText = styled.span({
  marginTop: SPACING.SMALL,
  display: 'flex',
});
const StyledBadge = styled(Badge)({
  gap: SPACING.SMALL,
  color: TEXT_COLORS.secondary,
});
const StyledSectionAuthorAttribution = styled(SectionAuthorAttribution)(
  truncate('175px', 1),
);

const StyledMagazineThumbnail = styled(MagazineThumbnail)({
  marginRight: SPACING.LARGE,
  background: ICON_COLORS.base,
});

type TopicMagazinesProps = {
  section: Flipboard.Section;
  t: Flipboard.TFunction;
} & ConnectAuthenticationProps &
  ConnectResponsiveProps;

const TopicMagazines: React.FC<TopicMagazinesProps> = ({
  section,
  isSmallScreen,
  isAuthenticated,
  t,
}) => {
  const currentSectionRemoteId = SectionUtil.getTopicSectionId(section);
  const topicMagazines =
    currentSectionRemoteId &&
    getAccessorySections(
      ACCESSORY_SECTIONS.TOPIC_RECOMMENDED_MAGAZINES,
      currentSectionRemoteId,
    ).slice(0, MAX_MAGAZINES);

  const showMagazines =
    !isSmallScreen && topicMagazines?.length > 0 && !isAuthenticated;

  if (!showMagazines) {
    return null;
  }

  const renderMagazine = (magazine) => {
    const hasMetrics = magazine.metricsText;
    return (
      <RecommendedMagazine key={magazine.remoteid}>
        <WebLink href={magazine.canonicalPath}>
          <StyledMagazineThumbnail
            name={magazine.title}
            size={72}
            image={magazine && magazine.image}
          />
        </WebLink>
        <MagazineAttributionContainer>
          <MagazineAttribution magazine={magazine} />
          <MagazineSecondaryText>
            {hasMetrics ? (
              magazine.metricsText
            ) : (
              <StyledBadge>
                <StyledSectionAuthorAttribution section={magazine} />
              </StyledBadge>
            )}
          </MagazineSecondaryText>
        </MagazineAttributionContainer>
        <FollowButton namePrefix="topic-header-magazine" section={magazine} />
      </RecommendedMagazine>
    );
  };

  return (
    <RecommendedMagazines>
      <Title>
        {t('discover_magazines', {
          topicTitle: section.title,
        })}
      </Title>
      {topicMagazines.map(renderMagazine)}
    </RecommendedMagazines>
  );
};

export default connector(
  connectResponsive,
  connectAuthentication,
)(withT(TopicMagazines));
