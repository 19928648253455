import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import WebLink from 'Webapp/shared/app/components/web-link';
import ItemSocialActions from 'Webapp/shared/app/components/item/item-social-actions';
import { BUTTON_SETS } from 'Webapp/shared/app/hocs/withItemActions';

import withT from 'ComponentLibrary/hocs/withT';

import { SPACING } from 'Style/spacing';
import { CONTENT_STORY_HEADING_TYPES, BODY_TYPES } from 'Style/typography';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import { TEXT_COLORS } from 'Style/colors';

const StyledStoryBoardFooterInline = styled.div({
  backgroundColor: 'var(--color--surface-secondary)',
  padding: `${SPACING.BASE8X} 0px`,
});

const StyledTitleRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  marginBottom: SPACING.LARGE,
});

const StyledsStoryboardFooterTitle = styled.h2({
  ...CONTENT_STORY_HEADING_TYPES.LARGE,
  marginRight: SPACING.BASE4X,
});

const StyledStoryBoardActions = styled.div({
  display: 'flex',
  flexDirection: 'row',
  '.item-social-actions': {
    '.social-actions': {
      backgroundColor: 'inherit',
    },
    '.social-actions__content': {
      backgroundColor: 'inherit',
    },
  },
  '.like-icon': {
    color: TEXT_COLORS.primary,
  },
  '.meatballs-menu-icon__meatball': {
    backgroundColor: TEXT_COLORS.primary,
  },
});

const StyledSpanTag = styled.span(BODY_TYPES.LARGE_STANDARD);

function InlineStoryboardFooter({ section, isPhone, t }) {
  const coverItem = section.items.find((item) => item.isSectionCover);
  /** Use the coverItem section as it does NOT match the incoming section, due to the
   * way our "editions" are wired.
   */
  const storyboardSection = coverItem?.section || section;
  const socialButtonSet = [BUTTON_SETS.SHARE_TEXT];
  if (!isPhone) {
    socialButtonSet.push(BUTTON_SETS.FLIP, BUTTON_SETS.LIKE);
  }
  return (
    <StyledStoryBoardFooterInline>
      <div className="centered-column">
        <StyledTitleRow>
          <StyledsStoryboardFooterTitle>
            <WebLink href={storyboardSection.canonicalPath}>
              {storyboardSection.title}
            </WebLink>
          </StyledsStoryboardFooterTitle>

          {coverItem && (
            <StyledStoryBoardActions className="storyboard-footer__actions">
              <ItemSocialActions
                item={coverItem}
                section={storyboardSection}
                articleRemoteId={coverItem.articleRemoteId}
                hideCounts
                buttonSet={
                  isPhone
                    ? BUTTON_SETS.INLINE_STORYBOARD_PHONE
                    : BUTTON_SETS.INLINE_STORYBOARD
                }
                hideMeatball={!isPhone}
              />
            </StyledStoryBoardActions>
          )}
        </StyledTitleRow>
        <StyledSpanTag>{t('inline_storyboard_footer_text')}</StyledSpanTag>
      </div>
    </StyledStoryBoardFooterInline>
  );
}

InlineStoryboardFooter.propTypes = {
  section: PropTypes.object.isRequired,
  isPhone: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};
export default connector(connectResponsive)(withT(InlineStoryboardFooter));
