import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SURFACE_COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { BREAKPOINTS } from 'Style/breakpoints';

import {
  CONTENT_STORY_HEADING_TYPES,
  UI_HEADING_TYPES,
  UI_SUBHEADING_TYPES,
} from 'Style/typography';
import { PAGE_STYLES } from 'Style/layout';

// Utils
import { WindowScroll, getDocumentScrollPosition } from 'Utils/window-scroll';
import SectionUtil from 'Utils/content/section-util';
import FlapUtil from 'Utils/content/flap-util';
import { USAGE_DISPLAY_STYLES } from 'Utils/analytics/usage';
import sentenceCase from 'Utils/content/sentence-case';

// Components
import { StyleVariations } from 'Webapp/shared/app/components/button';

import AuthorAvatar from 'Webapp/shared/app/components/attribution/author-avatar';
import FollowInvite from 'Webapp/shared/app/modals/follow-invite';
import SignupButton from 'Webapp/shared/app/containers/signup-button';
import LoginButton from 'Webapp/shared/app/components/login-button';

import withFrictionlessSignup from 'Webapp/shared/app/hocs/withFrictionlessSignup';
import withReCaptcha from 'Webapp/shared/app/hocs/withReCaptcha';
import withFollow from 'Webapp/shared/app/hocs/withFollow';

import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

const AVATAR_SIZE = 96;
const AVATAR_SIZE_CONDENSED = 80;

const BannerWrapper = styled.div(
  {
    display: 'flow-root',
    backgroundColor: SURFACE_COLORS.secondary,
  },
  BREAKPOINTS.phone({
    '*': {
      transition: 'all 0.3s',
    },
  }),
);
const InviteBanner = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isScrollAtTop',
})(
  PAGE_STYLES.CENTERED_COLUMN,
  {
    padding: `${SPACING.LARGE} 0`,
    display: 'flex',
    flexDirection: 'row',
    gap: SPACING.XLARGE,
    alignItems: 'center',
  },
  (props) =>
    BREAKPOINTS.phone(
      props.isScrollAtTop
        ? {
            marginTop: SPACING.XLARGE,
            flexDirection: 'column',
            '> *': { margin: ' 0 auto', textAlign: 'center' },
          }
        : {},
    ),
);

const InviterAvatar = styled(AuthorAvatar, {
  shouldForwardProp: (prop) => prop !== 'isScrollAtTop',
})(
  BREAKPOINTS.tabletPortraitUp({ width: `${AVATAR_SIZE}px` }),
  (props) =>
    BREAKPOINTS.phone(
      props.isScrollAtTop
        ? { height: `${AVATAR_SIZE}px` }
        : { width: `${AVATAR_SIZE}px` },
    ),
  {},
);

const InviteContents = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isScrollAtTop',
})(
  {
    display: 'flex',
    alignItems: 'center',
    gap: SPACING.XLARGE,
    flexDirection: 'row',
    width: '100%',
  },
  BREAKPOINTS.tabletPortraitUp({}),
  (props) =>
    BREAKPOINTS.phone({
      flexDirection: 'column',
      '> *': props.isScrollAtTop
        ? { margin: `0 auto`, textAlign: 'center' }
        : {},
    }),
);
const InviteDetails = styled.div({ marginRight: 'auto' });

const InviteTitle = styled.span(
  UI_HEADING_TYPES.MEDIUM,
  BREAKPOINTS.phone(CONTENT_STORY_HEADING_TYPES.LARGE),
);
const InviterText = styled.p(UI_SUBHEADING_TYPES.LARGE_STANDARD, {
  marginTop: SPACING.MEDIUM,
});
const AuthButtons = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isScrollAtTop',
})(
  {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: `${SPACING.BASE}`,
    height: 'fit-content',
    gap: SPACING.BASE4X,
  },
  (props) =>
    BREAKPOINTS.phone({
      gap: SPACING.LARGE,
      ...(props.isScrollAtTop
        ? {
            flexDirection: 'column',
            width: '100%',
          }
        : {}),
    }),
);

class InviteSignup extends Component {
  constructor(props) {
    super(props);
    this.state = { isScrollAtTop: true };

    this.windowScroll = new WindowScroll(this.handleScroll);
  }

  componentDidMount() {
    this.windowScroll.subscribe(this.handleScroll);
    this.handleScroll();
    this.maybeShowModal();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.modalLoginComplete !== this.props.modalLoginComplete &&
      this.props.modalLoginComplete
    ) {
      this.maybeShowModal();
    }
  }

  componentWillUnmount() {
    this.windowScroll.unsubscribe(this.handleScroll);
  }

  sectionMatchesSharedLinkData = () => {
    const { section, sharedLinkData } = this.props;
    return FlapUtil.isRemoteIdSectionMatch(sharedLinkData.remoteId, section);
  };

  maybeShowModal = () => {
    const {
      section,
      isAuthenticated,
      isFollowingSection,
      sharedLinkData,
      clearSharedLinkData,
      showModal,
    } = this.props;
    if (!sharedLinkData || !this.sectionMatchesSharedLinkData()) {
      return;
    }
    const alreadyFollowingSection = isFollowingSection(section);
    const alreadyFollowingUser = isFollowingSection(
      sharedLinkData.referringUser,
    );

    if (
      sharedLinkData.referringUser &&
      isAuthenticated &&
      (!alreadyFollowingSection || !alreadyFollowingUser)
    ) {
      showModal(FollowInvite, {
        alreadyFollowingSection,
        alreadyFollowingUser,
        section,
        onComplete: clearSharedLinkData,
        referringUser: sharedLinkData.referringUser,
      });
    }
  };

  handleScroll = () => {
    const isScrollAtTop = getDocumentScrollPosition() === 0;

    this.setState({ isScrollAtTop });
  };

  render() {
    const { isAuthenticated, section, isPhone, t, sharedLinkData } = this.props;
    const { isScrollAtTop } = this.state;

    if (
      !sharedLinkData ||
      !this.sectionMatchesSharedLinkData() ||
      !sharedLinkData.referringUser ||
      isAuthenticated
    ) {
      return null;
    }
    const { referringUser } = sharedLinkData;
    const inviteTitle = isPhone
      ? t('invite_friend_join_me')
      : referringUser.title;
    const decoratedTitle = SectionUtil.getDecoratedSectionTitle(section);
    const inviteText = isPhone
      ? t('invite_friend_banner_description_been_invited', {
          sectionTitle: decoratedTitle,
        })
      : t('invite_friend_banner_description_join_me', {
          sectionTitle: decoratedTitle,
        });

    return (
      <BannerWrapper>
        <InviteBanner isScrollAtTop={isScrollAtTop}>
          <InviterAvatar
            author={referringUser}
            avatarSize={
              isPhone && !isScrollAtTop ? AVATAR_SIZE_CONDENSED : AVATAR_SIZE
            }
            isScrollAtTop={isScrollAtTop}
          />
          <InviteContents isScrollAtTop={isScrollAtTop}>
            <InviteDetails>
              {(!isPhone || isScrollAtTop) && (
                <InviteTitle>{inviteTitle}</InviteTitle>
              )}
              <InviterText>{inviteText}</InviterText>
            </InviteDetails>
            <AuthButtons>
              <SignupButton
                name="main-nav-signup-cta"
                styleVariation={StyleVariations.PRIMARY}
                displayStyle={USAGE_DISPLAY_STYLES.NAV_BAR}
                label={sentenceCase(t('sign_up'))}
              />
              <LoginButton name="main-nav-login" />
            </AuthButtons>
          </InviteContents>
        </InviteBanner>
      </BannerWrapper>
    );
  }
}

InviteSignup.propTypes = {
  isAuthenticated: PropTypes.bool,
  modalLoginComplete: PropTypes.bool,
  section: PropTypes.object,
  onEmailChange: PropTypes.func.isRequired,
  onPendingSubscriptionGroupsChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmittable: PropTypes.bool.isRequired,
  pendingSubscriptionGroups: PropTypes.array,
  email: PropTypes.string.isRequired,
  onboardPending: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  dismissAllModals: PropTypes.func.isRequired,
  isPhone: PropTypes.bool,
  t: PropTypes.func,
  ReCaptchaUI: PropTypes.node,
  validateReCaptcha: PropTypes.func.isRequired,
  isReCaptchaPending: PropTypes.bool.isRequired,
  frictionlessEnter: PropTypes.func.isRequired,
  isFollowingSection: PropTypes.func.isRequired,
  clearSharedLinkData: PropTypes.func.isRequired,
  sharedLinkData: PropTypes.object,
};

InviteSignup.defaultProps = {};

export default connector(
  connectAuthentication,
  connectResponsive,
  connectModal,
)(withFollow(withFrictionlessSignup(withReCaptcha(InviteSignup))));
