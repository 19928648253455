import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SURFACE_COLORS, DefaultTextColorStyle } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { CONTENT_STORY_HEADING_TYPES } from 'Style/typography';
import { truncate } from 'Style/truncate';

// Utils
import StoryboardRenderingUtil from 'Utils/content/storyboard-rendering-util';
import withT from 'ComponentLibrary/hocs/withT';

// Components
import Badge from 'Webapp/shared/app/components/badge';
import WebLink from 'Webapp/shared/app/components/web-link';
import Image from 'Webapp/shared/app/components/base/image';
import CuratorAttribution from 'Webapp/shared/app/components/attribution/curator';

const TopicPageStoryboardItem = styled.div({
  ...DefaultTextColorStyle(true),
  backgroundColor: SURFACE_COLORS.defaultStoryboardCard, // Overridden in code by cover item
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  paddingLeft: SPACING.LARGE,
  height: '220px',
  overflow: 'hidden',

  'a.internal-link': {
    color: 'inherit',
  },
});

const StoryboardContents = styled.div({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const StyledBadge = styled(Badge)({
  paddingTop: SPACING.LARGE,
});

const Title = styled.h3(
  truncate('100%', 5),
  CONTENT_STORY_HEADING_TYPES.SMALL,
  {
    paddingTop: SPACING.BASE,
    paddingRight: SPACING.LARGE,
    color: 'inherit',
  },
);

const StyledAttribution = styled(CuratorAttribution)({
  color: 'inherit',
  alignSelf: 'flex-end',
  display: 'grid',
});
const StoryboardPhotoContainer = styled(WebLink)({
  color: 'inherit',
  width: '126px',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

function TopicStoryBoardItem({ relatedStoryBoardItem, t }) {
  const { title, image, internalURL } = relatedStoryBoardItem;
  const backgroundStyle = StoryboardRenderingUtil.getBackgroundColorStyle(
    image,
    0,
  );

  return (
    <li>
      <TopicPageStoryboardItem style={backgroundStyle}>
        <StoryboardContents>
          {/* TODO: Add prop navFromEventName for Weblink and rest of topic component */}
          <WebLink href={internalURL}>
            <StyledBadge isOverlay>{t('storyboard')}</StyledBadge>
            <Title>{title}</Title>
          </WebLink>
          <StyledAttribution item={relatedStoryBoardItem} avatarSize={32} />
        </StoryboardContents>
        <StoryboardPhotoContainer href={internalURL}>
          <Image image={image} alt={title} />
        </StoryboardPhotoContainer>
      </TopicPageStoryboardItem>
    </li>
  );
}

TopicStoryBoardItem.propTypes = {
  relatedStoryBoardItem: PropTypes.object,
  title: PropTypes.string,
  authorUsername: PropTypes.string,
  authorSection: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withT(TopicStoryBoardItem);
