import React from 'react';
import PropTypes from 'prop-types';
import { getAuthorUrl } from 'Utils/content/flipboard-urls';
import WebLink from '../web-link';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import withT from 'ComponentLibrary/hocs/withT';
import classNames from 'classnames';

function SectionAuthorAttribution({ className, section, t }) {
  if (!section) {
    return null;
  }

  const authorUrl = getAuthorUrl(section);

  const sectionAttribution = t('by_author', {
    authorDisplayName: section.authorDisplayName,
  });

  const attributionClass = classNames({
    className,
    'section-author-attribution': true,
    'ui-text--supporting': true,
  });
  if (authorUrl) {
    return (
      <p className={attributionClass}>
        <WebLink
          href={authorUrl}
          navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
        >
          {sectionAttribution}
        </WebLink>
      </p>
    );
  }

  return <p className={attributionClass}>{sectionAttribution}</p>;
}

SectionAuthorAttribution.propTypes = {
  className: PropTypes.string,
  section: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withT(SectionAuthorAttribution);
