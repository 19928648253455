import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { BREAKPOINTS } from 'Style/breakpoints';
import { SPACING } from 'Style/spacing';
import { CONTENT_HEADING_TYPES, BODY_TYPES } from 'Style/typography';
import { LAYOUT_SIZES, PAGE_STYLES } from 'Style/layout';
import { styleOnlyProps } from 'Style/style-helpers';
import { ICON_COLORS, COLORS } from 'Style/colors';

// Components
import SocialActions, {
  ACTIONS_ALIGNMENTS,
} from 'Webapp/shared/app/components/social-actions';
import TopicMagazines from './topic-magazines';
import TopicSectionTabs from './topic-section-tabs';
import TopicNewsletterSignup from 'Webapp/shared/app/views/section/topic/topic-newsletter-signup';
import DescriptionSectionsList from './description-sections-list';
import FollowButton from 'Webapp/shared/app/components/follow-button';
import InviteSignup from 'Webapp/shared/app/containers/invite-signup';

// HOCs
import withFlip from 'Webapp/shared/app/hocs/withFlip';
import withShare from 'Webapp/shared/app/hocs/withShare';
import withBreadcrumbs from 'Webapp/shared/app/hocs/withBreadcrumbs';
import withFollow from 'Webapp/shared/app/hocs/withFollow';
import withT from 'ComponentLibrary/hocs/withT';
import { BUTTON_SETS } from 'Webapp/shared/app/hocs/withItemActions';

import { ItemActionButtonType } from 'Webapp/enums';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';

const StyledTopicHeader = styled('div', styleOnlyProps('showMagazine'))(
  PAGE_STYLES.CENTERED_COLUMN,
  {
    marginTop: SPACING.BASE8X,
    display: 'grid',
    '.personalize-icon': {
      fill: ICON_COLORS.primary,
    },

    '.personalize-icon--personalized': {
      fill: COLORS.RED,
    },

    ' .share-icon, .flip-icon': {
      color: ICON_COLORS.primary,
    },

    '.meatballs-menu-icon__meatball': {
      backgroundColor: ICON_COLORS.primary,
    },
  },
  ({ showMagazine }) => {
    if (showMagazine) {
      return {
        minHeight: '304px',
      };
    }
  },
  BREAKPOINTS.tabletLandscapeUp({
    gridTemplateColumns: `minmax(auto, ${LAYOUT_SIZES.MAIN_COLUMN_HALF_WIDTH}) auto`,
    columnGap: SPACING.BASE8X,
  }),
  BREAKPOINTS.tabletPortraitDown({
    marginTop: SPACING.BASE4X,
  }),
  BREAKPOINTS.tabletLandscapeOnly({
    marginTop: SPACING.BASE6X,
  }),
  BREAKPOINTS.phone({
    minHeight: '226px',
  }),
);

const TopicFollowersRow = styled.div({
  display: 'flex',
  alignItems: 'start',
  marginBottom: SPACING.LARGE,
});
const TopicFollowers = styled.span(BREAKPOINTS.phone({ marginRight: 'auto' }));

const TopicTitleRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: SPACING.BASE4X,
  ...BREAKPOINTS.phone({ marginBottom: SPACING.LARGE }),
});
const TopicTitle = styled.h1({
  ...CONTENT_HEADING_TYPES.LARGE,
  marginRight: SPACING.XLARGE,
  ...BREAKPOINTS.phone({ wordBreak: 'break-word', marginRight: 'auto' }),
});

const TopicHeaderContents = styled.div(
  BODY_TYPES.LARGE_STANDARD,
  {
    marginBottom: SPACING.BASE6X,
  },
  BREAKPOINTS.phone({ marginBottom: SPACING.LARGE }),
);

const TopicHeaderDescription = styled.div(
  {
    marginBottom: SPACING.BASE6X,
  },
  BREAKPOINTS.phone({ marginBottom: SPACING.LARGE }),
);
const TopicDescriptionSectionList = styled(DescriptionSectionsList)(
  BODY_TYPES.LARGE_STANDARD,
  { marginBottom: SPACING.LARGE },
);

const TopicHeader = ({
  section,
  isPhone,
  isFollowing,
  isAuthenticated,
  signupCompleted,
  feedSection,
  subSections,
  updateFeedSection,
  topicDescription,
  relatedTopics,
  smartMagazineSection,
  profileSection,
  t,
}) => {
  const renderItemTopicList = () => {
    /**
     * TODO: For the moment, we are Excluding Featured Sections that can show up as subsections of
     * a smartMagazine. Figure out how we want to display them here.
     */

    const topicList = smartMagazineSection?.subsections || relatedTopics;
    return (
      <TopicDescriptionSectionList
        sections={topicList}
        personalizedName={
          smartMagazineSection &&
          profileSection &&
          profileSection.authorDisplayName
        }
      />
    );
  };

  const renderTopicHeaderMain = () => {
    const topicDescriptionText = topicDescription
      ? topicDescription
      : t('topic_default_description', { topicName: section.title });

    const buttonSet = [];

    if (isFollowing) {
      buttonSet.push(ItemActionButtonType.PERSONALIZE);
    }
    if (!isPhone) {
      buttonSet.push(ItemActionButtonType.FOLLOW);
    }
    const actionSheetButtonSet = [
      ...BUTTON_SETS.FLIP_SHARE_LIKE,
      ItemActionButtonType.ADD_FAVORITE,
    ];

    return (
      <TopicHeaderContents>
        <TopicFollowersRow>
          <TopicFollowers>{section.metricsText}</TopicFollowers>
          {isPhone && (
            <FollowButton
              key="social-actions_follow"
              namePrefix="social-actions"
              section={section}
            />
          )}
        </TopicFollowersRow>
        <TopicTitleRow>
          <TopicTitle>#{section.title}</TopicTitle>
          <SocialActions
            alignment={ACTIONS_ALIGNMENTS.LEFT}
            section={section}
            buttonSet={buttonSet}
            actionSheetButtonSet={actionSheetButtonSet}
          />
        </TopicTitleRow>
        {renderItemTopicList()}
        {(!isAuthenticated || !signupCompleted) && (
          /* We need to render TopicNewsletterSignup in most
          situations because we need to catch transitions from logged
          in / out, and logged out to signed-up */
          <React.Fragment>
            <TopicHeaderDescription>
              {topicDescriptionText}
            </TopicHeaderDescription>
            <TopicNewsletterSignup section={section} />
          </React.Fragment>
        )}
      </TopicHeaderContents>
    );
  };

  // We should remove this and clean up the header style to not depend on this being conditionally rendered.
  const MagazineComponent = <TopicMagazines section={section} />;
  return (
    <React.Fragment>
      <InviteSignup section={section} />
      <StyledTopicHeader showMagazine={!!MagazineComponent}>
        <div className="topic-header__details">{renderTopicHeaderMain()}</div>
        {MagazineComponent}
      </StyledTopicHeader>
      {subSections?.length > 0 && (
        <div className="centered-column">
          <TopicSectionTabs
            feedSection={feedSection}
            subSections={subSections}
            updateFeedSection={updateFeedSection}
          />
        </div>
      )}
    </React.Fragment>
  );
};

TopicHeader.propTypes = {
  section: PropTypes.object.isRequired,
  subSections: PropTypes.array,
  relatedTopics: PropTypes.array,
  topicCurators: PropTypes.array,
  topicMagazines: PropTypes.array,
  topicDescription: PropTypes.string,
  feedSection: PropTypes.object.isRequired,
  topicDescriptions: PropTypes.array,
  routing: PropTypes.object.isRequired,
  setBreadcrumbs: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateFeedSection: PropTypes.func.isRequired,
  isSmallScreen: PropTypes.bool,
  isPhone: PropTypes.bool,
  getRecommendedMagazinesByTopic: PropTypes.func,
  smartMagazineSection: PropTypes.object,
  profileSection: PropTypes.object,
  isFollowing: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  signupCompleted: PropTypes.bool.isRequired,
  featureFlags: PropTypes.object.isRequired,
};

TopicHeader.defaultProps = {
  topicDescriptions: null,
};

export default connector(
  connectResponsive,
  connectRouting,
  connectAmp,
  connectAuthentication,
  connectFeatureFlags,
)(withFollow(withBreadcrumbs(withShare(withFlip(withT(TopicHeader))))));
