import { connect } from 'react-redux';
import TopicHeader from '../../../views/section/topic/topic-header';
import FlapUtil from 'Utils/content/flap-util';
import Attribution from 'Utils/content/attribution';

// Utils
import SectionUtil from 'Utils/content/section-util';
import { getSmartMagazineSection } from 'Utils/content/profile-util';

const mapStateToProps = (
  {
    sections,
    app: {
      routing: { url },
    },
    auth: { uid },
    profile: { smartMagazines },
  },
  { section },
) => {
  const currentSectionRemoteId = SectionUtil.getTopicSectionId(section);
  const currentSection = FlapUtil.sectionOrDefaults(
    FlapUtil.getSectionByRemoteId(currentSectionRemoteId, sections.entries),
  );

  const isLoading = SectionUtil.isSectionLoading(
    currentSection.requestedRemoteId,
    sections.loading,
  );
  const topicDescription = SectionUtil.customizedTopicText(
    url,
    'description',
    sections.topicDescriptions,
  );

  const topicCurators =
    currentSection &&
    currentSection.items &&
    currentSection.items.map((item) => Attribution.getFlipper(item));

  const remoteid = FlapUtil.getRemoteidByUserid(uid);
  const profileSection = FlapUtil.getSectionByRemoteId(
    remoteid,
    sections.entries,
  );

  const smartMagazineSection = getSmartMagazineSection(section, smartMagazines);

  return {
    subSections: currentSection.subSections,
    relatedTopics: currentSection.topics,
    topicCurators,
    isLoading,
    neverLoadMore: currentSection.neverLoadMore,
    followers: currentSection.followerProjections,
    followersLoading: currentSection.followersLoading,
    topicDescription,
    smartMagazineSection,
    profileSection,
  };
};

const mapDispatchToProps = () => ({});

const TopicHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopicHeader);

export default TopicHeaderContainer;
