import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { CONTENT_HEADING_TYPES, BODY_TYPES } from 'Style/typography';

const Title = styled.div({
  marginBottom: SPACING.LARGE,
  ...CONTENT_HEADING_TYPES.LARGE,
});
const SectionMetrics = styled.div(BODY_TYPES.SMALL_STANDARD);

function TopicBodyBottomBanner({ section }) {
  return (
    <React.Fragment>
      <Title>{`#${section.title}`}</Title>
      <SectionMetrics>{section.metricsText}</SectionMetrics>
    </React.Fragment>
  );
}

TopicBodyBottomBanner.propTypes = {
  section: PropTypes.object,
};

export default TopicBodyBottomBanner;
