import { connect } from 'react-redux';
import InlineStoryboard from '../../views/section/inline-storyboard';

import FlapUtil from 'Utils/content/flap-util';

const mapStateToProps = ({ sections }, { section }) => {
  const currentSection = FlapUtil.sectionOrDefaults(
    FlapUtil.getSectionByRemoteId(section.remoteid, sections.entries),
  );

  return {
    items: currentSection.items,
  };
};

const StoryboardContainer = connect(mapStateToProps, {})(InlineStoryboard);

export default StoryboardContainer;
