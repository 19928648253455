import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'Webapp/shared/app/components/base/input';
import Button from 'Webapp/shared/app/components/button';
import LoadingSpinner from 'ComponentLibrary/icons/loading-spinner';
import SignupDisclaimer from 'Webapp/shared/app/components/signup-disclaimer';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { UI_SUBHEADING_TYPES } from 'Style/typography';
import { BREAKPOINTS } from 'Style/breakpoints';
import { SURFACE_COLORS } from 'Style/colors';
import { EMAIL_REGEXP } from 'Utils/reg-exp';
import {
  groupsWithUpdatedIsSubscribed,
  groupForSection,
  subscriptionForSection,
  findSubscription,
  localizedSubscriptionName,
} from 'Webapp/shared/utils/email-settings';

import withT from 'ComponentLibrary/hocs/withT';
import { FrictionlessFlowType } from 'Webapp/enums';
import withFrictionlessSignup from 'Webapp/shared/app/hocs/withFrictionlessSignup';
import withReCaptcha from 'Webapp/shared/app/hocs/withReCaptcha';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectEmailSettings from 'Webapp/shared/app/connectors/connectEmailSettings';

const Wrapper = styled.div(
  BREAKPOINTS.phone({
    backgroundColor: SURFACE_COLORS.tertiary,
    padding: SPACING.LARGE,
  }),
);

const SignupCopy = styled.div(UI_SUBHEADING_TYPES.LARGE_STANDARD, {
  marginBottom: SPACING.BASE,
});

const Form = styled.form(
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: SPACING.BASE,
    marginBottom: SPACING.BASE,
  },
  BREAKPOINTS.phone({
    display: 'block',
  }),
);

const ButtonWrapper = styled.div(
  BREAKPOINTS.phone({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
);

const EmailInput = styled(Input)(
  {
    flexGrow: '1',
  },
  BREAKPOINTS.phone({
    marginBottom: SPACING.BASE,
  }),
);

const SubmitButton = styled(Button)({
  width: 'initial',
});

class TopicNewsletterSignup extends Component {
  constructor(props) {
    super(props);
    this.isFormValid = this.isFormValid.bind(this);
    this.updatePendingGroupsWithTargetSubscription =
      this.updatePendingGroupsWithTargetSubscription.bind(this);
    this.targeEmailGroup = this.targetHardCodedGroup.bind(this);
    this.targetEmailSubscription = this.targetHardCodedSubscription.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { emailGroupsLoaded, getEmailSettingsMapping } = this.props;
    if (emailGroupsLoaded) {
      this.updatePendingGroupsWithTargetSubscription();
    } else {
      getEmailSettingsMapping();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.emailGroupsLoaded && this.props.emailGroupsLoaded) {
      this.updatePendingGroupsWithTargetSubscription();
    }
  }

  targetHardCodedGroup() {
    const { section } = this.props;
    return section && groupForSection(section);
  }

  targetHardCodedSubscription() {
    const { section } = this.props;
    return section && subscriptionForSection(section);
  }

  updatePendingGroupsWithTargetSubscription() {
    const { emailGroups, onPendingSubscriptionGroupsChange } = this.props;

    const decoratedEmailGroups =
      (this.targetHardCodedGroup() &&
        this.targetHardCodedSubscription() &&
        groupsWithUpdatedIsSubscribed(
          emailGroups,
          this.targetHardCodedGroup(),
          this.targetHardCodedSubscription(),
          true,
        )) ||
      emailGroups;

    onPendingSubscriptionGroupsChange(decoratedEmailGroups);
  }

  isFormValid() {
    return EMAIL_REGEXP.test(this.props.email);
  }

  async submit(e) {
    e.preventDefault();
    const { onSubmit, validateReCaptcha } = this.props;
    await validateReCaptcha('newsletters_landing', async () => {
      onSubmit(e, FrictionlessFlowType.TOPIC);
    });
  }

  render() {
    const {
      t,
      email,
      onboardPending,
      onEmailChange,
      isSubmittable,
      emailGroups,
      isReCaptchaPending,
      ReCaptchaUI,
      frictionlessEnter,
      isAuthenticated,
    } = this.props;
    if (isAuthenticated) {
      /* The parent renders this when authenticated because of
      frictionless usage things, but we shouldn't show it once you are
      authenticated. */
      return null;
    }
    const hardCodedGroup = this.targetHardCodedGroup();
    const hardCodedSubscription = this.targetHardCodedSubscription();

    if (!hardCodedGroup || !hardCodedSubscription) {
      return null;
    }

    const subscription = findSubscription(
      emailGroups,
      hardCodedGroup,
      hardCodedSubscription,
    );

    if (!subscription) {
      return null;
    }

    const submitDisabled = isReCaptchaPending || !isSubmittable;

    return (
      <Wrapper>
        <SignupCopy>
          {t('inline_newsletter_signup_title', {
            newsletter_name: localizedSubscriptionName(t, subscription),
          })}
        </SignupCopy>
        <Form onSubmit={this.submit}>
          <EmailInput
            type="email"
            name="email"
            placeholder={t('newsletters_email_placeholder')}
            value={email}
            onChange={onEmailChange}
            onKeyUp={() => frictionlessEnter(FrictionlessFlowType.TOPIC)}
            disabled={onboardPending}
          />
          <ButtonWrapper>
            {onboardPending ? (
              <LoadingSpinner />
            ) : (
              <SubmitButton
                name="newsletters__form-submit"
                className="button--primary button--block"
                type="submit"
                disabled={submitDisabled}
              >
                {t('newsletters_submit_button')}
              </SubmitButton>
            )}
          </ButtonWrapper>
        </Form>
        {ReCaptchaUI}
        <SignupDisclaimer isNewsletter />
      </Wrapper>
    );
  }
}

TopicNewsletterSignup.propTypes = {
  emailGroups: PropTypes.array,
  emailGroupsLoaded: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  section: PropTypes.object,
  onEmailChange: PropTypes.func.isRequired,
  onPendingSubscriptionGroupsChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pendingSubscriptionGroups: PropTypes.array,
  email: PropTypes.string.isRequired,
  onboardPending: PropTypes.bool.isRequired,
  isSubmittable: PropTypes.bool.isRequired,
  ReCaptchaUI: PropTypes.node,
  validateReCaptcha: PropTypes.func.isRequired,
  isReCaptchaPending: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  frictionlessEnter: PropTypes.func.isRequired,
  getEmailSettingsMapping: PropTypes.func.isRequired,
};

TopicNewsletterSignup.defaultProps = {
  section: null,
  emailGroups: [],
  pendingSubscriptionGroups: null,
};

export default connector(
  connectEmailSettings,
  connectResponsive,
  connectAuthentication,
)(withFrictionlessSignup(withReCaptcha(withT(TopicNewsletterSignup))));
