import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TopicSection from '../../views/section/topic';
import FlapUtil from 'Utils/content/flap-util';
import { getSmartMagazineSection } from 'Utils/content/profile-util';
import { getRelatedSectionsByIdKey } from 'Utils/content/item-util';

import {
  getSection,
  loadNextTopicPage,
} from 'Webapp/shared/app/redux/actions/section-actions';
import {
  usageTrackReachedSectionEnd,
  usageTrackTopicSubtabSelected,
} from 'Webapp/shared/app/redux/actions/usage-actions';
import { RelatedSectionType } from 'Webapp/enums';
import { isAuthenticated } from 'Webapp/shared/app/redux/selectors/auth';

const mapStateToProps = (state, { section }) => {
  const {
    sections,
    profile: { smartMagazines },
  } = state;
  const currentSectionRemoteId = section.isSmartMagazine
    ? section.rootTopic?.remoteid
    : section.remoteid;
  const currentSection = FlapUtil.sectionOrDefaults(
    FlapUtil.getSectionByRemoteId(currentSectionRemoteId, sections.entries),
  );

  const topicSections = getRelatedSectionsByIdKey(
    section,
    sections.entries,
    RelatedSectionType.TOPICS,
  );

  const smartMagazineSection = getSmartMagazineSection(section, smartMagazines);
  return {
    isAuthenticated: isAuthenticated(state),
    sections: sections.entries,
    sectionsLoading: sections.loading,
    followers: currentSection.followerProjections,
    followersLoading: currentSection.followersLoading,
    topicDescriptions: sections.topicDescriptions,
    relatedTopicsSections: topicSections && topicSections.slice(0, 3),
    smartMagazineSection,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSection,
      loadNextTopicPage,
      usageTrackReachedSectionEnd,
      usageTrackTopicSubtabSelected,
    },
    dispatch,
  );

const TopicContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopicSection);

export default TopicContainer;
