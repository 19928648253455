import React from 'react';
import PropTypes from 'prop-types';
import codeSplitLoad from 'Webapp/shared/utils/code-split-load';
import styled from '@emotion/styled';

import { noOpFn } from 'Utils/no-op-fn';

import connector from 'Utils/connector';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';

const StoryboardLayout = codeSplitLoad('StoryboardLayout');

const StyledStoryBoardLayoutContainer = styled.div({
  '.storyboard-layout-redesigned': {
    marginLeft: 'unset',
    marginRight: 'unset',
    maxWidth: 'unset',
  },
});
function InlineStoryboard({ section, items }) {
  const coverItem = items.find((item) => item.isSectionCover);
  /** Use the coverItem section as it does NOT match more typically used incoming section,
   * due to the way our "editions" are wired.
   */
  const storyboardSection = coverItem?.section || section;

  return (
    <StyledStoryBoardLayoutContainer className="storyboard-layout">
      <StoryboardLayout
        section={storyboardSection}
        items={items}
        coverItem={coverItem}
        loadNextPage={noOpFn} // Handled by wrapping section page
        isInline
      />
    </StyledStoryBoardLayoutContainer>
  );
}

InlineStoryboard.propTypes = {
  section: PropTypes.object,
  items: PropTypes.array.isRequired,
};

InlineStoryboard.defaultProps = {};

export default connector(connectRouting)(InlineStoryboard);
