import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual, shuffle } from 'lodash';
import styled from '@emotion/styled';
import { LAYOUT_SIZES } from 'Style/layout';

import {
  ACCESSORY_SECTIONS,
  getAccessorySections,
} from 'Webapp/shared/concepts/accessory-sections';

// Utils
import SectionUtil from 'Utils/content/section-util';
import FlapUtil from 'Utils/content/flap-util';

// Components
import NoContentGuard from 'Webapp/shared/app/components/no-content-guard';
import ItemList, { ITEM_LIST_STYLE_MODIFIERS } from './item-list';
import VisibilityScrollToBottomTracker from 'Webapp/shared/app/components/visibility-scroll-to-bottom-tracker';
import LoadMoreScrollToBottomTracker from 'Webapp/shared/app/components/load-more-scroll-to-bottom-tracker';
import Loading from 'Webapp/shared/app/components/loading';
import TopicHeader from 'Webapp/shared/app/containers/section/topic/topic-header';
import ContextSection from '../../../app/components/context-section';
import CTABannerRedesigned from '../../components/cta-banner-redesigned';
import InlineStoryboard from 'Webapp/shared/app/containers/section/inline-storyboard';
import InlineStoryboardFooter from 'Webapp/shared/app/themes/storyboard-layout/inline-storyboard-footer';
import TopicBodyBottomBanner from './topic/topic-body-bottom-banner';
import TopicRelatedStoryboards from './topic/topic-storyboards';

// HOCs
import withFlip from 'Webapp/shared/app/hocs/withFlip';
import withShare from 'Webapp/shared/app/hocs/withShare';
import withBreadcrumbs from 'Webapp/shared/app/hocs/withBreadcrumbs';
import withT from 'ComponentLibrary/hocs/withT';
import withFollow from 'Webapp/shared/app/hocs/withFollow';
import withContentVeil from 'Webapp/shared/app/hocs/withContentVeil';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectFlipMode from 'Webapp/shared/app/connectors/connectFlipMode';
import connectCurrentUser from 'Webapp/shared/app/connectors/connectCurrentUser';

const IN_FEED_MAGAZINE_INDEX = 16;
const IN_FEED_MAGAZINE_INDEX_PHONE = 5;
const IN_FEED_TOPIC_INDEX = 43;
const IN_FEED_TOPIC_INDEX_PHONE = 14;

const StyledTopicSection = styled.div((props) => ({
  paddingTop: props.isFlipModeEnabled
    ? LAYOUT_SIZES.MAIN_NAV_PLUS_FLIP_MODE_HEIGHT
    : null,
}));

class TopicSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      feedSection: props.section,
      randomMagazine: props.randomMagazine,
      shouldRenderTopicFeedModule: true,
    };
  }

  componentDidMount() {
    const { section, setBreadcrumbs } = this.props;
    setBreadcrumbs({
      section,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { section, followingIds, smartMagazineSection, setPositionTopValue } =
      this.props;

    if (
      !isEqual(
        this.props.relatedTopicsSections,
        prevProps.relatedTopicsSections,
      )
    ) {
      setPositionTopValue();
    }

    if (this.props.section.remoteid !== prevProps.section.remoteid) {
      this.setState({
        feedSection: this.props.section,
      });
      setPositionTopValue();
    }

    if (this.state.feedSection?.remoteid !== prevState.feedSection?.remoteid) {
      this.props.usageTrackTopicSubtabSelected({
        rootTopicId: section.remoteid,
        section: this.state.feedSection,
      });

      setPositionTopValue();
    }

    /**
     * Handling for the generated in feed topic module. Either the followingIds
     * are loaded into redux or the user has clicked to another topic.
     */
    if (
      (prevProps?.followingIds?.length === 0 && followingIds?.length) ||
      prevProps.section.remoteid !== this.props.section.remoteid
    ) {
      const topicSectionTopics = smartMagazineSection
        ? smartMagazineSection.subsections
        : section.topics;
      const unfollowedTopics = this.props.filterUnfollowed(topicSectionTopics);

      if (unfollowedTopics?.length <= 3) {
        this.setState({
          shouldRenderTopicFeedModule: false,
        });
      }
    }
  }

  updateFeedSection = async (updatedFeedSection) => {
    const { getSection, sections } = this.props;

    const feedSection = FlapUtil.getSectionByRemoteId(
      updatedFeedSection.remoteid,
      sections,
    );

    this.setState({
      feedSection: updatedFeedSection,
    });

    if (!feedSection) {
      await getSection(updatedFeedSection.remoteid, {
        limit: 6,
      });
    }
  };

  insertItemIntoFeed(itemsToRender, item, index) {
    if (!itemsToRender || itemsToRender.length < index) {
      return itemsToRender;
    }
    const updatedItems = [
      ...itemsToRender.slice(0, index),
      { ...item, isClientSideFeedItem: true },
      ...itemsToRender.slice(index),
    ];
    return updatedItems;
  }

  /**
   * Modifies a list of itemsToRender by inserting the pseudo/items.
   * @param {} itemsToRender
   * @returns modified list of itemsToRender
   */
  addGeneratedFeedItemModules(itemsToRender) {
    const { section, isPhone, smartMagazineSection } = this.props;
    if (!itemsToRender) {
      return null;
    }

    let updatedItems = itemsToRender;
    if (this.state.randomMagazine) {
      updatedItems = this.insertItemIntoFeed(
        updatedItems,
        this.state.randomMagazine,
        isPhone ? IN_FEED_MAGAZINE_INDEX_PHONE : IN_FEED_MAGAZINE_INDEX,
      );
    }

    if (
      this.state.shouldRenderTopicFeedModule &&
      (smartMagazineSection ? smartMagazineSection.subsections : section.topics)
        ?.length > 0
    ) {
      updatedItems = this.insertItemIntoFeed(
        updatedItems,
        smartMagazineSection || section,
        isPhone ? IN_FEED_TOPIC_INDEX_PHONE : IN_FEED_TOPIC_INDEX,
      );
    }
    return updatedItems;
  }

  render() {
    const {
      sections,
      sectionsLoading,
      section,
      contributors,
      loadNextTopicPage,
      isAmp,
      usageTrackReachedSectionEnd,
      relatedTopicsSections,
      setPositionTopValue,
      veilShown,
      renderContentVeil,
      currentUserData,
    } = this.props;

    const { feedSection } = this.state;
    const currentFeedSection =
      feedSection &&
      FlapUtil.getSectionByRemoteId(feedSection.remoteid, sections);
    const feedSectionIsLoading =
      sectionsLoading &&
      SectionUtil.isSectionLoading(feedSection.remoteid, sectionsLoading);

    let itemsToRender = currentFeedSection
      ? SectionUtil.itemsToRender(
          currentFeedSection,
          currentFeedSection.items,
          currentUserData,
        )
      : [];
    const hasItemsToRender = itemsToRender.length > 0;
    /**
     * Insert client generated feed cards, if the feed has items. These will always be tied to the MAIN section.
     */
    if (hasItemsToRender) {
      itemsToRender = this.addGeneratedFeedItemModules(itemsToRender);
    }

    if (!feedSectionIsLoading) {
      setPositionTopValue();
    }

    const relatedTopics = relatedTopicsSections.map(
      (relatedTopic) =>
        relatedTopic && (
          <div
            className="topic-section__related-story"
            key={relatedTopic.remoteid || relatedTopic.title}
          >
            <ContextSection
              section={relatedTopic}
              sectionItemLimit={6}
              condensedItems
              hideSeeMore
            />
          </div>
        ),
    );
    relatedTopics.splice(
      1,
      0,
      <CTABannerRedesigned key="cta-banner-redesigned" />,
    );
    const neverLoadMore =
      !feedSectionIsLoading && currentFeedSection?.neverLoadMore;

    const shouldLoadMoreContents =
      !veilShown ||
      currentFeedSection?.isStoryboard ||
      relatedTopics.length <= 1;

    const bottomBanner = <TopicBodyBottomBanner section={section} />;

    const showRelatedStoryboardSection =
      !currentFeedSection?.isStoryboard &&
      SectionUtil.mightBeSameSection(currentFeedSection, section);
    return (
      <StyledTopicSection className="topic-section">
        <TopicHeader
          section={section}
          feedSection={feedSection}
          topicDescriptions={this.props.topicDescriptions}
          updateFeedSection={this.updateFeedSection}
        />
        {!hasItemsToRender && feedSectionIsLoading ? (
          <Loading isView />
        ) : (
          <React.Fragment>
            {showRelatedStoryboardSection && (
              <TopicRelatedStoryboards section={section} />
            )}
            <section className="centered-column">
              <NoContentGuard hasContent={hasItemsToRender}>
                {currentFeedSection?.isStoryboard ? (
                  <InlineStoryboard
                    section={currentFeedSection}
                    items={itemsToRender}
                  />
                ) : (
                  <ItemList
                    section={currentFeedSection}
                    items={itemsToRender}
                    sectionHasContributors={contributors.length > 0}
                    styleModifier={ITEM_LIST_STYLE_MODIFIERS.GRID}
                  />
                )}
                {loadNextTopicPage &&
                  !neverLoadMore &&
                  !isAmp &&
                  !veilShown && (
                    <LoadMoreScrollToBottomTracker
                      immediateInfinite
                      loadNext={() => loadNextTopicPage(currentFeedSection)}
                      isLoading={feedSectionIsLoading}
                      onScrolledToBottom={() =>
                        usageTrackReachedSectionEnd(currentFeedSection)
                      }
                      waitUntilMoved={false}
                    />
                  )}
                {neverLoadMore && (
                  <VisibilityScrollToBottomTracker
                    onScrolledToBottom={() =>
                      usageTrackReachedSectionEnd(currentFeedSection)
                    }
                  />
                )}
              </NoContentGuard>
            </section>
            {currentFeedSection?.isStoryboard && (
              <InlineStoryboardFooter section={currentFeedSection} />
            )}
            {!shouldLoadMoreContents && renderContentVeil(bottomBanner)}
            {relatedTopics}
          </React.Fragment>
        )}
      </StyledTopicSection>
    );
  }
}

TopicSection.propTypes = {
  sections: PropTypes.array,
  sectionsLoading: PropTypes.object,
  section: PropTypes.object.isRequired,
  topicDescriptions: PropTypes.array,
  setBreadcrumbs: PropTypes.func.isRequired,
  usageTrackReachedSectionEnd: PropTypes.func.isRequired,
  usageTrackTopicSubtabSelected: PropTypes.func.isRequired,
  contributors: PropTypes.array,
  loadNextTopicPage: PropTypes.func,
  isAmp: PropTypes.bool.isRequired,
  getSection: PropTypes.func,
  relatedTopicsSections: PropTypes.array,
  randomMagazine: PropTypes.object,
  isPhone: PropTypes.bool,
  t: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  smartMagazineSection: PropTypes.object,
  followingIds: PropTypes.array,
  filterUnfollowed: PropTypes.func,
  setPositionTopValue: PropTypes.func.isRequired,
  renderContentVeil: PropTypes.func.isRequired,
  veilShown: PropTypes.bool,
  isFlipModeEnabled: PropTypes.bool.isRequired,
  currentUserData: PropTypes.object,
};

TopicSection.defaultProps = {
  topicDescriptions: null,
  contributors: [],
  relatedTopicsSections: [],
  isAuthenticated: false,
};

/**
 * Temporary hack for loading the accessory section via hook without having to rewrite
 * the class component above. Ideally we would simplify the above by moving this hook logic
 * out down to the sub-component that uses the magazine. We are mutating the items to render
 * with some client generated feed cards in the lifecycle so it needs to be available in
 * an earlier render.
 */
const TopicSectionShim = (props) => {
  const { section } = props;
  const currentSectionRemoteId = SectionUtil.getTopicSectionId(section);

  const topicMagazines = getAccessorySections(
    ACCESSORY_SECTIONS.TOPIC_RECOMMENDED_MAGAZINES,
    currentSectionRemoteId,
  );

  const randomMagazine =
    topicMagazines &&
    shuffle(
      // only magazines that have topics will available
      topicMagazines.filter((m) => m.topics.length > 0),
      'remoteid',
    )[0];

  return <TopicSection {...props} randomMagazine={randomMagazine} />;
};

TopicSectionShim.propTypes = {
  section: PropTypes.object.isRequired,
};

export default connector(
  connectResponsive,
  connectAmp,
  connectFlipMode,
  connectCurrentUser,
)(
  withBreadcrumbs(
    withContentVeil(withShare(withFlip(withFollow(withT(TopicSectionShim))))),
  ),
);
