import connector from 'Utils/connector';
import {
  sharedLinkDataSelector,
  clearSharedLinkData,
} from 'Webapp/shared/concepts/shared-link';

import InviteSignup from 'Webapp/shared/app/components/invite-signup';

export default connector({
  selectors: {
    sharedLinkData: sharedLinkDataSelector,
  },
  actions: {
    clearSharedLinkData,
  },
})(InviteSignup);
